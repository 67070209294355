<template>
  <div style="width: 100%">
    <div
      style="
        width: 100%;
        height: 2.88rem;
        background-color: white;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          top: 0.768rem;
          left: 0.64rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 0.96rem;
          color: #040407;
        "
      >
        易喆云
      </div>
      <!-- <img v-show="isHome()" :src="menu" alt="" style="width: 1.2672rem;position: absolute;top:0.8rem;right: 0.64rem"
           @click="goHome(8)"> -->
    </div>
    <div class="topBack" style="width: 100%; position: relative">
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
        "
      >
        <div style="width: 70%; margin-left: 0.9rem">
          <img :src="black" class="div_a_a" style="width: 100%" />
        </div>
        <div
          style="
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            :src="mobile_xiazai_button"
            class="div_a_c"
            style="width: 3.5rem"
          />
          <div
            style="
              font-family: 146-SSQingYaSongTi, 146-SSQingYaSongTi;
              font-size: 0.8rem;
              color: #121d42;
              margin: 15px 0;
            "
          >
            天乙万年历
          </div>
          <div
            @click="goHome(11)"
            style="
              background: #ef2b37;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 0.7rem;
              color: #ffffff;
              border-radius: 31px 31px 31px 31px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 9px 0;
            "
          >
            立即下载
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; background: #f9fbff">
      <!-- 第一栏 -->
      <div style="width: 100%; display: flex; flex-direction: row;justify-content: center;align-items: center;    padding: 33px 0;">
        <div style="width: 34%;">
          <div class="header">
            <p>万年历查询</p>
            <img
              :src="icon"
              style="width: 27px; margin-left: 16px"
            />
          </div>
          <div class="title">
            随时随地，轻松查询万年历，了解历史和未来的日期信息，方便安排生活与工作，让每一天都不遗漏。
          </div>
        </div>
        <div style="position: relative;width: 60%;display: flex;justify-content: center;">
          <img
            :src="mobile_bg1"
            style="width: 80%; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 100%;
              position: absolute;
              bottom: -2rem;
              right: 0rem;
              z-index: 1;
            "
          />
        </div>
        <!-- <div>
          <img :src="mobile_bg1" style="width: 100%;position: relative; z-index: 3"/>
        </div> -->
      </div>
        <!-- 2 -->
        <div style="width: 100%; display: flex; flex-direction: row;justify-content: center;align-items: center;    padding: 33px 0;">
          <div style="position: relative;width: 60%;display: flex;justify-content: center;">
          <img
            :src="mobile_bg2"
            style="width: 80%; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 100%;
              position: absolute;
              bottom: -2rem;
              right: 0rem;
              z-index: 1;
            "
          />
        </div>
        <div style="width: 34%;">
          <div class="header">
            <p>个人历查询</p>
            <img
              :src="icon1"
              style="width: 27px; margin-left: 16px"
            />
          </div>
          <div class="title">
            根据您的出生日期，生成专属个人历，
            助您把握每一个重要时刻，
            洞悉人生轨迹，提升生活质量。
          </div>
        </div>
        
        <!-- <div>
          <img :src="mobile_bg1" style="width: 100%;position: relative; z-index: 3"/>
        </div> -->
      </div>

      <!-- 3 -->

      <div style="width: 100%; display: flex; flex-direction: row;justify-content: center;align-items: center;    padding: 33px 0;">
        <div style="width: 34%;">
          <div class="header">
            <p>老黄历查询</p>
            <img
              :src="icon2"
              style="width: 27px; margin-left: 16px"
            />
          </div>
          <div class="title">
            传承古老智慧，随时查询老黄历，
            获取节日、吉日、忌日等重要信息，
            为您的决策提供指导，让生活更加顺畅。
          </div>
        </div>
        <div style="position: relative;width: 60%;display: flex;justify-content: center;">
          <img
            :src="mobile_bg3"
            style="width: 80%; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 100%;
              position: absolute;
              bottom: -2rem;
              right: 0rem;
              z-index: 1;
            "
          />
        </div>
        <!-- <div>
          <img :src="mobile_bg1" style="width: 100%;position: relative; z-index: 3"/>
        </div> -->
      </div>

      <!-- 4 -->

      <div style="width: 100%; display: flex; flex-direction: row;justify-content: center;align-items: center;    padding: 33px 0;">
        <div style="position: relative;width: 60%;display: flex;justify-content: center;">
          <img
            :src="mobile_bg4"
            style="width: 80%; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 100%;
              position: absolute;
              bottom: -2rem;
              right: 0rem;
              z-index: 1;
            "
          />
        </div>
        <div style="width: 34%;">
          <div class="header">
            <p>巡时养生</p>
            <img
              :src="icon3"
              style="width: 27px; margin-left: 16px"
            />
          </div>
          <div class="title">
            根据时间变化，提供科学养生建议，
            让您在不同的时节都能保持最佳状态，
            享受健康生活。
          </div>
        </div>
      </div>
      <!-- 5 -->
      <div style="width: 100%; display: flex; flex-direction: row;justify-content: center;align-items: center;    padding: 33px 0;">
        <div style="width: 34%;">
          <div class="header">
            <p>九宫飞星</p>
            <img
              :src="icon4"
              style="width: 27px; margin-left: 16px"
            />
          </div>
          <div class="title">
            运用九宫飞星理论，
            分析空间布局与流年运势，
            帮助您优化居住和工作环境，
            提升运势，助力生活每一天。
          </div>
        </div>
        <div style="position: relative;width: 60%;display: flex;justify-content: center;">
          <img
            :src="mobile_bg5"
            style="width: 80%; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 100%;
              position: absolute;
              bottom: -2rem;
              right: 0rem;
              z-index: 1;
            "
          />
        </div>
        <!-- <div>
          <img :src="mobile_bg1" style="width: 100%;position: relative; z-index: 3"/>
        </div> -->
      </div>
      <!-- 6 -->
      <div style="width: 100%; display: flex; flex-direction: row;justify-content: center;align-items: center;    padding: 33px 0;">
        <div style="position: relative;width: 60%;display: flex;justify-content: center;">
          <img
            :src="mobile_bg6"
            style="width: 80%; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 100%;
              position: absolute;
              bottom: -2rem;
              right: 0rem;
              z-index: 1;
            "
          />
        </div>
        <div style="width: 34%;">
          <div class="header">
            <p>日程提醒</p>
            <img
              :src="icon5"
              style="width: 27px; margin-left: 16px"
            />
          </div>
          <div class="title">
            智能日程提醒，轻松管理您的 生活与工作安排。无论是重要的会议、生日还是节日，都能及时提醒，让您不错过每一个重要时刻。
          </div>
        </div>
        
      </div>
      <div style="position: relative">
        <bottom></bottom>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "@/mixins/comment";
import moment from "moment/moment";
import bottom from "@/components/bottom.vue";

export default {
  mixins: [mixin],
  name: "downloadMobile.vue",
  components: { bottom },
  data() {
    return {
      dy: require("@/assets/div_1/dy.png"),
      icon: require("@/assets/div_1/search.png"),
      icon1: require("@/assets/div_1/man.png"),
      icon2: require("@/assets/div_1/book.png"),
      icon3: require("@/assets/div_1/time.png"),
      icon4: require("@/assets/div_1/fly.png"),
      icon5: require("@/assets/div_1/ld.png"),
      black: require("@/assets/div_1/tianyiback.png"),
      mobile_xiazai_button: require("@/assets/div_2/logo.png"),
      endTime: "",
      mobile_bg1: require("@/assets/div_2/background2.png"),
      mobile_bg2: require("@/assets/div_1/background_8.png"),
      mobile_bg3: require("@/assets/div_1/background_4.png"),
      mobile_bg4: require("@/assets/div_1/background_5.png"),
      mobile_bg5: require("@/assets/div_1/background_6.png"),
      mobile_bg6: require("@/assets/div_1/background_7.png"),
    };
  },
  methods: {
    isHome() {
      return this.$route.name === "首页";
    },
  },
  created() {},
  mounted() {
    this.endTime = moment().format("YYYY");
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  height: auto;
}
.topBack {
  background-image: url(@/assets/div_2/background.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: REEJI-HaoHengGB-Flash-Black, REEJI-HaoHengGB-Flash-Black;
  font-weight: bold;
  font-size: 16px;
  color: #121d42;
}
.title {
  font-family: DengXian-Bold, DengXian-Bold;
  font-weight: normal;
  font-size: 13px;
  color: #121d42;
  margin-top: 12px;
}
</style>
